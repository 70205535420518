import { Suspense } from 'react';
import { Flex } from '@radix-ui/themes';
import Spline from '@splinetool/react-spline';

import Loading from '../Loading';

const Hero = () => {
  return (
    <div className='h-svh w-screen flex flex-col items-center justify-center bg-[#0d0d0d]'>
      <Flex
        gap={{ initial: '5', md: '8' }}
        align='center'
        justify='center'
        direction='column'
        className='absolute w-full h-svh z-10'
      >
        <h1 className='text-4xl md:text-7xl font-bold text-whiteColor text-center'>
          Research & Innovation Day
          <br />
          22 May, 2025
        </h1>

        <p className='text-whiteColor text-3xl md:text-5xl font-bold text-center'>
          Coming Soon
        </p>

        {/* <Link to='/register'>
          <Button className='bg-primaryColor text-md md:text-base'>
            REGISTER NOW
          </Button>
        </Link> */}
      </Flex>

      <div className='h-svh w-full'>
        <Suspense fallback={<Loading />}>
          <Spline scene='https://prod.spline.design/5Pjh9PpAVKqDI25n/scene.splinecode' />
        </Suspense>
      </div>
    </div>
  );
};

export default Hero;
