import { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';

const About = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ['Experience', 'Aspire', 'Learn', 'Dream', 'Create'];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((currentWord) => (currentWord + 1) % words.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <Container
      size='4'
      align='center'
      width='100%'
      px={{
        initial: '5',
        md: '0',
      }}
    >
      <Flex
        direction={{ initial: 'column', md: 'row' }}
        align='center'
        justify='center'
        width='100%'
        gap={{ initial: '5', md: '0' }}
      >
        <Flex
          direction='row'
          align='center'
          justify='center'
          gap={{ initial: '3', md: '5' }}
          className='basis-2/5 items-center'
        >
          <h1 className='text-blackColor font-bold text-3xl md:text-5xl'>We</h1>

          <h1 className='text-whiteColor font-bold text-3xl md:text-5xl bg-primaryColor px-3 md:px-4 py-2 md:py-3 rounded-xl'>
            {words[currentWord]}
          </h1>
        </Flex>

        <p className='basis-3/5 text-primaryColor text-lg md:text-xl font-medium text-justify'>
          Join us at BITS Pilani Dubai Campus, a premier UAE university, for
          Research & Innovation Day 2025! Explore cutting-edge research across
          engineering, science, management, and humanities in our advanced labs
          and centers. This scholar-organized event bridges the gap between
          academics and industry. Interact with renowned experts through talks,
          panels, and poster presentations. Witness groundbreaking research at
          BITS Dubai and spark collaborations with faculty & Industry experts
          from across the UAE. Together, let's leverage innovative research to
          achieve a sustainable future.
        </p>
      </Flex>
    </Container>
  );
};

export default About;
