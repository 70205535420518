import { Container, Flex } from '@radix-ui/themes';

const RD2024 = () => {
  return (
    <Container size='4' align='center' width='100%'>
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '4', md: '8' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-primaryColor text-center'>
          Research Day 2024 - Recap
        </h1>

        <p className='text-primaryColor text-sm md:text-md font-medium text-justify'>
          BITS Pilani, Dubai Campus hosted the BITS Research and Innovation Day
          3.0 on May 22, 2024, attracting a distinguished panel of experts and
          scholars to discuss various facets of technology, innovation, and
          entrepreneurship. The event attracted more than 45 industry experts
          and academicians in addition to around 85 posters from students and
          scholars from BITS Pilani, Dubai. The event featured insightful talks
          by renowned speakers and concluded with a dynamic panel discussion,
          providing a platform for students, researchers, and industry
          professionals to engage and exchange ideas.
          <br />
          <br />
          A distinguished panel of experts in their respective fields delivered
          invited talks. Mr. Shalabh Jakhetia, Managing Partner & Chief
          Operating Officer, iAccel Gulf Business Incubator, delivered an
          informative session titled "How to Register Startups in the UAE." He
          outlined the procedural steps essential for launching startups in the
          UAE, emphasizing the region's supportive ecosystem for new businesses,
          Dubai's locational advantages and the importance of understanding
          regulatory frameworks to ensure compliance and success.
          <br />
          <br />
          Ms. Manisha Jadhav, IP Head at Awalnut IPR Consultants, spoke on
          "Patent Filing Process in India and UAE and Patent Law and IPR." She
          detailed the intricacies of patent filing in both regions,
          highlighting differences and similarities in the legal requirements.
          Ms. Jadhav also underscored the significance of protecting
          intellectual property to foster innovation and competitive advantage.
          <br />
          <br />
          Dr. Jasveer R. Beni, R&D Manager - Cementitious Systems at Technology
          Center Dubai, spoke on "3D Printing in Construction." His talk
          outlined the revolutionary potential of 3D printing technology in the
          construction industry, focusing on its benefits such as reduced waste,
          cost efficiency, and the ability to create complex structures with
          high precision.
          <br />
          <br />
          Dr. Michael Baddeley, Research Lead at Technology Innovation Institute
          (TII), Abu Dhabi, discussed "Cybersecurity Trends and Challenges
          Ahead." He emphasized the importance of staying ahead of cyber threats
          through continuous research and innovation in security technologies.
          Additionally, he talked about resilient low-power wireless
          communication and mesh networks, exploring their use cases in smart
          cities, IoT applications, and disaster management, emphasizing their
          potential to provide robust and energy-efficient connectivity
          solutions.
          <br />
          <br />
          Dr. Sameer Kishore, Head and Founder of VRX Lab at Middlesex
          University, and a BITS Pilani Dubai Campus Alumni, explored
          "Understanding the 'Self' in Immersive Virtual Reality." His
          presentation delved into the psychological and cognitive aspects of
          immersive VR experiences, examining how virtual environments can
          influence self-perception and behavior. Additionally, Dr. Kishore
          discussed the potential use of VR in research, highlighting its
          applications in studying human behavior, creating controlled
          experimental settings, and enhancing data collection methods through
          immersive simulations.
          <br />
          <br />
          The panel discussion, moderated by Dr. Gulshan Kuman, Assistant
          Professor, Department of Mechanical Engineering, brought together
          experts to discuss "Technology, Innovation, and Entrepreneurship - UAE
          Perspective." The panelists included: Dr. Jinane Mounsef, Professor,
          EEE, Rochester Institute of Technology; Dr. Hafiz Ahmed, Professor,
          RAK Medical & Health Sciences University; Mr. Arun Mohan, CEO,
          ADfolks, Dubai; Mr. Joy Sebastian, CEO, Techgentsia Software
          Technologies Pvt. Ltd.; Mr. Renjit Philip, Partner, Start2Scale
          Consulting (MENA); Mr. Arun Kumar, Associate Director, Technology and
          Innovation Research, Fuld and Company. The discussion covered several
          key points:
          <br />
          <br />
          <ul className='list-disc pl-8'>
            <li>
              Emerging Technologies in the UAE: The panelists discussed the rise
              of technologies like Blockchain, FinTech, Large Language Learning
              Models, AI etc. in the UAE, noting their transformative impact on
              various sectors.
            </li>
            <li>
              Industry Adoption: They highlighted how industries in the UAE are
              integrating these technologies to enhance efficiency, drive
              innovation, and maintain competitive advantage.
            </li>
            <li>
              Role of Educational Institutions: they discussed how universities
              and educational institutions play a crucial role in fostering
              industry adoption of new technologies through research, training,
              and collaboration.
            </li>
            <li>
              Impact on Entrepreneurship and Startups: The discussion emphasized
              how these emerging technologies are creating new opportunities for
              startups and entrepreneurs in the UAE, fostering a vibrant and
              dynamic business environment.
            </li>
            <li>
              Development Strategies: The panelists shared prevalent strategies
              in the UAE for developing these technologies, including government
              initiatives, public-private partnerships, and investment in R&D.
            </li>
            <li>
              New and Upcoming Trends: Future trends such as advancements in AI,
              the expansion of the blockchain ecosystem, and the growing
              importance of cybersecurity as well as other technological
              advancements were highlighted towards the conclusion of this
              interesting panel discussion.
            </li>
          </ul>
          <br />
          <br />
          The event also featured poster presentations from students and PhD
          scholars of BITS Pilani Dubai, showcasing more than 85 posters across
          fields like Engineering, Sciences, Humanities, and Management.
          <br />
          <br />
          These presentations provided a glimpse into the innovative research
          being conducted at the campus and offered students a platform to share
          their findings with the academic and professional community. Ten PhD
          scholars and two First Degree students were received Best Poster
          awards.
          <br />
          <br />
          Our research collaborators from Industries and Universities in UAE
          were awarded in recognition and appreciation of their collaboration
          and continued support.
          <br />
          <br />
          BITS Research and Innovation Day 3.0 was a resounding success,
          fostering dialogue on cutting-edge topics and demonstrating the
          critical role of technology and innovation in shaping the future. The
          event underscored BITS Pilani Dubai's commitment to academic
          excellence and industry collaboration, paving the way for future
          advancements and entrepreneurial ventures in the UAE.
        </p>

        <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
          {Array.from({ length: 27 }, (_, index) => (
            <img
              key={index}
              loading='lazy'
              className='h-auto max-w-full rounded-lg object-cover object-center'
              src={`/assets/RD2024/image${index + 1}.jpg`}
              alt={`RD2024_${index + 1}`}
            />
          ))}
        </div>
      </Flex>
    </Container>
  );
};

export default RD2024;
